import { render, staticRenderFns } from "./UiMultiselect.vue?vue&type=template&id=f5dbdfe4"
import script from "./UiMultiselect.vue?vue&type=script&setup=true&lang=js"
export * from "./UiMultiselect.vue?vue&type=script&setup=true&lang=js"
import style0 from "@vueform/multiselect/themes/default.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./UiMultiselect.vue?vue&type=style&index=1&id=f5dbdfe4&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports