import { computed } from '@nuxtjs/composition-api';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2';


export default {
  __name: 'UiMultiselect',
  props: {
  value: {
    type: [Number, String, Array],
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: '',
  },
  mode: {
    type: String,
    default: 'single',
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  valueProp: {
    type: String,
    default: 'id',
  },
  label: {
    type: String,
    default: 'name',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  canClear: {
    type: Boolean,
    default: true,
  },
  canDeselect: {
    type: Boolean,
    default: true,
  },
  inputLabel: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: 'medium',
  },
},
  emits: ['input'],
  setup(__props, { emit }) {

const props = __props;

const sizes = {
  small: '--ms-py: 0.85rem;',
  medium: '--ms-py: 1rem;',
};




const valueLocal = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('input', value);
  },
});

const styles = sizes[props.size];

return { __sfc: true,sizes, props, emit, valueLocal, styles, Multiselect }
}

}