
import { defineComponent, computed } from '@nuxtjs/composition-api';

const variants = {
  base: 'text-gray-600 bg-white',
  success: 'text-green-600 bg-green-50',
  danger: 'text-orange-400 bg-orange-50',
};

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: 'base',
    },
  },
  setup(props) {
    const classes = computed(() => [variants[props.variant]]);

    return {
      classes,
    };
  },
});
