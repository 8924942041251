import { onMounted } from '@nuxtjs/composition-api';


export default {
  __name: 'UiRadio',
  setup(__props) {

onMounted(async () => {
  if (typeof window !== 'undefined') {
    await import('@shoelace-style/shoelace/dist/components/radio/radio.js');
  }
});

return { __sfc: true, }
}

}