
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: 'rounded-xl',
    },
    inputTextAlign: {
      type: String,
      default: 'left',
    },
    min: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'base',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const valueLocal = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('input', newValue);
      },
    });

    const input = ref();
    const variants = {
      base: 'border-gray-100',
      accent: 'border-orange-400',
    };
    const classes = computed(() => [
      variants[props.variant],
      props.error && 'border-red',
      props.rounded,
      props.label && 'placeholder:focus:opacity-100 placeholder:opacity-0',
    ]);

    const blur = () => {
      input.value.blur();
    };

    return {
      valueLocal,
      input,
      classes,
      blur,
    };
  },
});
