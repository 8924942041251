
import { defineComponent } from '@nuxtjs/composition-api';

const variants = {
  primary: 'bg-blue-50 text-blue-700',
  success: 'bg-green-50 text-green-600',
};

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
  setup(props) {
    const classes = [variants[props.variant]];

    return {
      classes,
    };
  },
});
