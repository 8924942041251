
import { defineComponent, onMounted } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  setup(_, { emit }) {
    const onChange = (e) => {
      emit('input', e.target.value);
    };

    onMounted(async () => {
      if (typeof window !== 'undefined') {
        await import(
          '@shoelace-style/shoelace/dist/components/radio-group/radio-group.js'
        );
      }
    });

    return {
      onChange,
    };
  },
});
