
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { useElementBounding } from '@vueuse/core';
import { CdnImg } from '@webplatform/cdn-image';
import { useScroll } from '~/composables';

const defaultData = {
  amountOfDevices: 100,
  currency: 'USD',
  macPrice: 1900,
  pcPrice: 1400,
  pcLifeCicle: 3,
  cashPaymentPer: 365,
  commercialCreditCalculationToggler: true,
  creditPer: 365,
  costOfCommercialCredit: 18,
  pcAntivirusPrice: 61920,
  monthsOfCredit: 24,
  mobileDeviceManagementToggler: true,
  macMobileDeviceManagementPrice: 94.68,
  pcTimeOfDeployment: 60,
  macTimeOfDeployment: 10,
  expenseOfSpecialist: 3000,
  pcNumberOfIncidents: 3,
  macNumberOfIncidents: 1.2,
  pcTimeOfSupportTime: 30,
};

export default defineComponent({
  components: {
    CdnImg,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    translations: {
      type: Object,
      default: () => ({
        enter_the_amount_of_devices: 'Enter the amount of devices',
        choose_currency: 'Choose a currency',
        pc: 'PC',
        mac: 'Mac',
        cost_of_device_per_unit: 'Cost of device per unit',
        initial_price_difference: 'Initial price difference',
        enter_expected_life_cycle_of_pc: 'Enter expected life cycle of a PC',
        cost_per: 'Cost per',
        in_case_of: 'in case of',
        cash_payment: 'cash payment',
        lifetime_gain_per_day: 'Lifetime gain per day',
        commercial_credit_calculation: 'Commercial credit calculation',
        commercial_credit_calculation_tip:
          '(move the slider Off if credit is not needed)',
        cost_of_commercial_credit_for_one_year:
          'Cost of commercial credit for one year, %',
        months_of_credit: 'Months of credit',
        cost_off_financing_per_unit: 'Cost of Financing per unit',
        of_credit: 'of credit',
        antivirus_security: 'Antivirus security. Annual Subsription price',
        native: 'Native',
        mobile_device_management:
          'Mobile Device Management . Annual subsription price',
        mac_mobile_device_management_price_tip:
          '(we recommend Jamf MDM for Mac or IOS)',
        average_cost_of_memory:
          'Average cost of memory, storage, CPU upgrades is 7% during use time of the device',
        time_of_deployment:
          'Time of deployment including apps uppload, minutes',
        deployment_costs_per_device: 'Deployment costs per device',
        average_number_of_incidents_per_year:
          'Average number of incidents per year',
        average_time_of_it_support_time:
          'Average time of IT support time, minutes',
        average_expense_of_it_support_specialist_per_month:
          'Average expense of IT support specialist per month',
        it_support_costs_per_device_per_year:
          'IT support costs per device per year',
        total_costs_per_device_per_year: 'Total costs per device per year',
        sales_price_of_the_device_after_use:
          'Sales price of the device after 3 years of use',
        sales_price_of_the_device_after_use_tip:
          '(Future Buy-back is extremely useful to discount cost of renewal)',
        total_cost_of_ownership: 'Total Cost of Ownership (TCO)',
        total_cost_of_ownership_tip: 'for 3 years of ownership',
        month: 'month',
        mac_are_cheaper_by: 'Mac are cheaper by',
        email_title: 'Want to receive the result by email?',
        email_placeholder: 'example@email.com',
        send: 'Send',
        form_success_alert: 'Form successfully sended',
      }),
    },
    periods: {
      type: Array,
      default: () => [
        {
          id: 365,
          name: 'Day',
        },
        {
          id: 52,
          name: 'Week',
        },
        {
          id: 12,
          name: 'Month',
        },
      ],
    },
    monthsOfCreditOptions: {
      type: Array,
      default: () => [12, 24, 36],
    },
    currencies: {
      type: Array,
      default: () => [
        {
          id: 'USD',
          name: 'USD (USA)',
        },
        {
          id: 'EUR',
          name: 'Euro',
        },
      ],
    },
  },
  setup(props) {
    const { isScrollingBottom } = useScroll();
    const { $axios } = useContext();
    const data = reactive({
      ...defaultData,
      ...props.config,
    });

    const topPanel = ref();
    const bottomPanel = ref();
    const { top: topPanelTop } = useElementBounding(topPanel);

    const rates = ref([]);

    const macLifeCicle = computed(() => data.pcLifeCicle + 2);

    const initialPriceDifference = computed(
      () =>
        Math.round(
          (data.macPrice - data.pcPrice) * data.amountOfDevices * 100,
        ) / 100,
    );

    const initialPriceDifferencePercentage = computed(() =>
      Math.round((data.macPrice / data.pcPrice - 1) * 100),
    );

    const pcCostCashPayment = computed(
      () =>
        Math.round(
          (data.pcPrice / data.pcLifeCicle / data.cashPaymentPer) * 100,
        ) / 100,
    );

    const macCostCashPayment = computed(
      () =>
        Math.round(
          (data.macPrice / macLifeCicle.value / data.cashPaymentPer) * 100,
        ) / 100,
    );

    const lifetimeGain = computed(
      () =>
        Math.round(
          Math.abs(macCostCashPayment.value - pcCostCashPayment.value) *
            data.amountOfDevices *
            100,
        ) / 100,
    );

    const lifetimeGainPercentage = computed(() =>
      Math.round((macCostCashPayment.value / pcCostCashPayment.value) * 100),
    );

    const pcCostOfFinancingPerUnit = computed(() =>
      data.commercialCreditCalculationToggler
        ? Math.round(
            ((data.pcPrice * data.costOfCommercialCredit) / 100) *
              (data.monthsOfCredit / 12) *
              100,
          ) / 100
        : 0,
    );

    const macCostOfFinancingPerUnit = computed(() =>
      data.commercialCreditCalculationToggler
        ? Math.round(
            ((data.macPrice * data.costOfCommercialCredit) / 100) *
              (data.monthsOfCredit / 12) *
              100,
          ) / 100
        : 0,
    );

    const pcCreditCost = computed(() =>
      data.commercialCreditCalculationToggler
        ? Math.round(
            ((data.pcPrice + pcCostOfFinancingPerUnit.value) /
              data.pcLifeCicle /
              data.creditPer) *
              100,
          ) / 100
        : 0,
    );

    const macCreditCost = computed(() =>
      data.commercialCreditCalculationToggler
        ? Math.round(
            ((data.macPrice + macCostOfFinancingPerUnit.value) /
              macLifeCicle.value /
              data.creditPer) *
              100,
          ) / 100
        : 0,
    );

    const pcMobileDeviceManagementPrice = computed(() => {
      return data.mobileDeviceManagementToggler === true
        ? Math.round(data.macMobileDeviceManagementPrice * 1.1 * 100) / 100
        : 0;
    });

    const pcAverageCostOfMemory = computed(() =>
      (data.pcPrice * 0.07).toFixed(2),
    );

    const pcDeploymentCostsPerDevice = computed(
      () =>
        Math.round(
          data.pcTimeOfDeployment *
            (data.expenseOfSpecialist / 22 / 8 / 60) *
            100,
        ) / 100,
    );

    const macDeploymentCostsPerDevice = computed(
      () =>
        Math.round(
          data.macTimeOfDeployment *
            (data.expenseOfSpecialist / 22 / 8 / 60) *
            100,
        ) / 100,
    );

    const macTimeOfSupportTime = computed(
      () => Math.round(data.pcTimeOfSupportTime * 0.75 * 100) / 100,
    );

    const pcSupportCostsPerDevice = computed(
      () =>
        Math.round(
          (data.expenseOfSpecialist / 22 / 8 / 60) *
            (data.pcNumberOfIncidents * data.pcTimeOfSupportTime) *
            100,
        ) / 100,
    );

    const macSupportCostsPerDevice = computed(
      () =>
        Math.round(
          (data.expenseOfSpecialist / 22 / 8 / 60) *
            (data.macNumberOfIncidents * macTimeOfSupportTime.value) *
            100,
        ) / 100,
    );

    const pcTotalCostPerDevice = computed(
      () =>
        Math.round(
          (pcDeploymentCostsPerDevice.value + pcSupportCostsPerDevice.value) *
            100,
        ) / 100,
    );

    const macTotalCostPerDevice = computed(
      () =>
        Math.round(
          (macDeploymentCostsPerDevice.value + macSupportCostsPerDevice.value) *
            100,
        ) / 100,
    );

    const pcSalesPriceAfter3Years = computed(() =>
      (data.pcPrice * 0.08).toFixed(2),
    );

    const macSalesPriceAfter3Years = computed(() =>
      (data.macPrice * 0.3).toFixed(2),
    );

    const pcTotalCost = computed(() =>
      (
        data.pcPrice +
        pcCostOfFinancingPerUnit.value +
        (data.pcAntivirusPrice +
          pcMobileDeviceManagementPrice.value +
          pcTotalCostPerDevice.value) *
          data.pcLifeCicle +
        +pcAverageCostOfMemory.value -
        pcSalesPriceAfter3Years.value
      ).toFixed(2),
    );

    const macTotalCost = computed(() =>
      (
        data.macPrice +
        macCostOfFinancingPerUnit.value +
        ((data.mobileDeviceManagementToggler
          ? +data.macMobileDeviceManagementPrice
          : 0) +
          macTotalCostPerDevice.value) *
          macLifeCicle.value -
        macSalesPriceAfter3Years.value
      ).toFixed(2),
    );

    const macsCheaperBy = computed(() =>
      Math.abs(
        Math.round(
          (macTotalCost.value - pcTotalCost.value) * data.amountOfDevices,
        ),
      ),
    );

    const macsCheaperByPercentage = computed(() =>
      Math.round((1 - macTotalCost.value / pcTotalCost.value) * 100),
    );

    const getRates = async () => {
      const {
        data: { rates: ratesData },
      } = await $axios.get('https://openexchangerates.org/api/latest.json', {
        params: {
          app_id: 'ded8b6caff5640c09e05546dc80b9772',
        },
      });
      return ratesData;
    };

    const convert = (currency) => {
      const moneyVariables = [
        'macPrice',
        'pcPrice',
        'pcAntivirusPrice',
        'macMobileDeviceManagementPrice',
        'expenseOfSpecialist',
      ];
      moneyVariables.forEach((variable) => {
        if (data.currency === 'USD') {
          data[variable] = data[[variable]] * rates.value[currency];
        } else {
          data[variable] =
            (data[variable] / rates.value[data.currency]) *
            rates.value[currency];
        }
      });
      data.currency = currency;
    };

    const getResultVariant = (value1, value2, params) => {
      if (value1 === value2) return 'base';
      if (params.revert) return +value2 < +value1 ? 'success' : 'danger';
      return +value2 < +value1 ? 'danger' : 'success';
    };

    const formData = reactive({
      email: '',
    });

    const formStates = reactive({
      isVisible: false,
      pending: false,
      sended: false,
    });

    const sendForm = async () => {
      formStates.pending = true;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          formStates.pending = false;
          formStates.sended = true;
          formData.email = '';
        }, 2000);
      });
    };

    onBeforeMount(async () => {
      rates.value = await getRates();
    });

    return {
      topPanel,
      bottomPanel,
      topPanelTop,
      data,
      macLifeCicle,
      initialPriceDifference,
      initialPriceDifferencePercentage,
      pcCostCashPayment,
      macCostCashPayment,
      lifetimeGain,
      lifetimeGainPercentage,
      pcCostOfFinancingPerUnit,
      macCostOfFinancingPerUnit,
      pcCreditCost,
      macCreditCost,
      pcMobileDeviceManagementPrice,
      pcAverageCostOfMemory,
      pcDeploymentCostsPerDevice,
      macDeploymentCostsPerDevice,
      macTimeOfSupportTime,
      pcSupportCostsPerDevice,
      macSupportCostsPerDevice,
      pcTotalCostPerDevice,
      macTotalCostPerDevice,
      pcSalesPriceAfter3Years,
      macSalesPriceAfter3Years,
      pcTotalCost,
      macTotalCost,
      macsCheaperBy,
      macsCheaperByPercentage,
      rates,
      convert,
      getResultVariant,
      isScrollingBottom,
      formData,
      formStates,
      sendForm,
    };
  },
});
