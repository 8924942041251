
import { defineComponent, onMounted } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const handleChange = (event) => {
      emit('input', event.target.checked);
    };

    onMounted(async () => {
      if (typeof window !== 'undefined') {
        await import(
          '@shoelace-style/shoelace/dist/components/switch/switch.js'
        );
      }
    });

    return {
      handleChange,
    };
  },
});
